import { createSlice } from '@reduxjs/toolkit';

const tableRowSlice = createSlice({
  name: 'tableRow',
  initialState: {
    info_rows: [],
    buy_rows: [],
    sell_rows: [],
  },
  reducers: {
    updateData: (state, action) => {
      // console.log(action.payload);
        switch (action.payload.type) {
            case 'info':
                state.info_rows = action.payload.data;
                break;
            case 'buy':
                state.buy_rows = action.payload.data;
                break;
            case 'sell':
                state.sell_rows = action.payload.data;
                break;
            default:
              return
        }
    }
  },
});

export const { updateData } = tableRowSlice.actions;
export default tableRowSlice.reducer;
