import { createSlice } from "@reduxjs/toolkit";

export const AlertSlice = createSlice({
  name: "alert",
  initialState: {
    alerts: [],
    notification: [],
    dialogs: []
  },
  reducers: {
    createAlert: (state, action) => {
      state.alerts.push({
        message: action.payload.message,
        type: action.payload.type,
        timeout: action.payload.timeout ?? null
      });
    },
    removeAlert: (state, action) => {
      state.alerts.splice(action.payload.index, 1);
    },
    removeDialogAlert: (state, action) => {
      if(action.payload?.index)
        state.alerts.splice(action.payload.index, 1);
      else
        state.dialogs.pop();
    },
    addDialogAlert: (state, action) => {
      if (action.payload.title || action.payload.message)
        state.dialogs.push(action.payload)
      
    },
    addNotification: (state, action) => {
      state.notification.push({
        "type": action.payload.type,
        "message": action.payload.message,
        "read": false
      })
      
    },
    readNotification: (state, action) => {
      for (const i in state.notification){
        state.notification[i].read = true
      }
    }
  }
});

export const { createAlert, removeAlert, readNotification, addNotification, addDialogAlert, removeDialogAlert } = AlertSlice.actions;

export default AlertSlice.reducer;