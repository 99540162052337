import React from 'react'
import { useDispatch, useSelector  } from 'react-redux';
import { Fab, Box } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'; 
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LiveDataTable from '../../Tables/LiveDataTable';
import MarketIndices from '../../Tables/MarketIndices';
import UserPosition from '../../Tables/UserPosition';
import { emitDataSocket } from "../../../features/slices/socket/socketSlice";
import { addNotification, addDialogAlert } from "../../../features/slices/alert/TransitionAlertsSlice";
import { getResultList } from "../../../features/slices/events/eventSlice";
import SideBar from './SideBar';
import {SocketContext}  from '../../../utils/SocketContext';
import {socketAPI} from '../../../utils/axiosInstance';

const info_local = require('../../../jsons/tables/info_ui.json');
const sell_local = require('../../../jsons/tables/sell_ui.json');
const buy_local = require('../../../jsons/tables/buy_ui.json');

const local_visiblity = localStorage.getItem('tableVisiblityPref')
const tableVisiblityPref = local_visiblity ? {...info_local.columnVisibilityModel, ...JSON.parse(local_visiblity)} : info_local.columnVisibilityModel

const liveDataColumnVisiblityShow = {}
info_local?.column.forEach((v)=>{
  if(!tableVisiblityPref.hasOwnProperty(v.key)){
    tableVisiblityPref[v.key] = true
  }
  if(v.hidable){
    liveDataColumnVisiblityShow[v.header] = v.key
  }
})
sell_local?.column.forEach((v)=>{
  if(!tableVisiblityPref.hasOwnProperty(v.key)){
    tableVisiblityPref[v.key] = true
  }
  if(v.hidable){
    liveDataColumnVisiblityShow[v.header] = v.key
  }
})
buy_local?.column.forEach((v)=>{
  if(!tableVisiblityPref.hasOwnProperty(v.key)){
    tableVisiblityPref[v.key] = true
  }
  if(v.hidable){
    liveDataColumnVisiblityShow[v.header] = v.key
  }
})

const initLiveTables = [
  {
    name: buy_local.heading,
    color: '#22c55e',
    pref: buy_local,
    show: true
  },
  {
    name: sell_local.heading,
    pref: sell_local,
    color: "#f72b2b",
    show: true
  },
  {
    name: info_local.heading,
    pref: info_local,
    color: "#f472b6",
    show: true
  },

]

const LiveData = () => {

  const dispatch = useDispatch();
  const { socket, connectSocket } = React.useContext(SocketContext); 
  const resultData = useSelector((state) => state.events.result.list);
  const [expiryOptions, setExpiryOptions] = React.useState([]);
  const [instrumentExpiry, setInstrumentExpiry] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [deleteOptions, setDeleteOptions] = React.useState({});
  const [anchorExpiry, setAnchorExpiry] = React.useState(null);
  const [liveDataTables, setLiveDataTables] = React.useState(initLiveTables)
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(tableVisiblityPref);

  React.useEffect(()=>{

    const list = [];

    selectedItems.map(async (i) =>{
      if(instrumentExpiry[i]){
        instrumentExpiry[i].forEach((e)=>{
          if(!list.includes(e)){
            list.push(e);
          }
        })
      }else{
        const resp = await socketAPI.get(`instruments/expiry-list/${i}`);
        if (resp.data && resp.data.success && resp.data.data){
          setInstrumentExpiry((d)=> ({...d, [i]: resp.data.data }))
          resp.data.data.forEach((e)=>{
            if(!list.includes(e)){
              list.push(e);
            }
          })
        }
      }
    })
    setExpiryOptions(list);

  },[selectedItems, instrumentExpiry])

  const remove_instrument = (type, instrument) => {
    const req = {
      "event": "update_preferences",
      "pref_type": "wait_list",
      "type": type,
      "instrument": instrument,
      "expiry": ""
    }
    dispatch(emitDataSocket({ req, socket}))
  }

  React.useEffect(()=>{
    if(!socket){
      connectSocket();
    }

    if (socket) {
      socket.on("session", (res)=>{
        if(res && res.reload){
          dispatch(addDialogAlert({message:"New tab opened !", buttons: [{name: 'Use here', actionType: 'SESSION_RESTORE' }] }));
        }
      })
    }

    return () => {
      if (socket){
        socket.off("session");
      }
    }
  }, [socket]);

  React.useEffect(() => {
    
    dispatch(getResultList());
  }, []);

  const deleteLiveDataInstruments = () => {

    for (const [key, value] of Object.entries(deleteOptions)) {
      if (value.length)
        remove_instrument(key, value);
    }
  }
  
  const updateExpiryMultiple = async (exp) => {

    const BODY = {
      instruments: selectedItems,
      expiry: exp
    }

    try {
      const resp = await socketAPI.post("instruments/change-expiry-multiple", BODY);
    
      if (resp && resp.data && resp.data.success){
        const errorList = resp.data.errorList ?? [];
        if (errorList){
          dispatch(addNotification({
            type: 'info',
            message: `Expiry not changed for ${errorList}...`
          }))
        }
      }

      setAnchorExpiry(null)
      
    } catch (error) {
      console.warn("Error in changing expiry...", error)
    }
    
    

  }
  
  return (
    <Box>

      <UserPosition />
      <MarketIndices/>

      {
        liveDataTables.map((t) => 
          t.show ? <LiveDataTable resultData={resultData} key={`liveTab_${t.name}`} pref={t.pref} columnVisibilityModel={columnVisibilityModel} setDeleteOptions={setDeleteOptions} setSelectedItems={setSelectedItems} /> : null
        )
      }

      {
        (Object.values(deleteOptions).some(list => list.length)) > 0 ?
        <>
          <Fab size='medium' color='primary' variant="extended" sx={{position: 'fixed', bottom: 70, right: 35}} onClick={ (e) => setAnchorExpiry(e.currentTarget)} > 
            <ChangeCircleIcon />
          </Fab>
          <Menu anchorEl={anchorExpiry} open={Boolean(anchorExpiry)} onClose={()=> setAnchorExpiry(null)}  MenuListProps={{'aria-labelledby': 'userNameShow' }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
          { 
            expiryOptions.map((d,ulI) => 
              <MenuItem sx={{ minWidth: '150px', justifyContent: 'center' }}  key={`expiryOptions_${ulI}`} onClick={() => updateExpiryMultiple(d) }>{d}</MenuItem>
            )  
          }
              
          </Menu>
          <Fab size='medium' color='primary' variant="extended" sx={{position: 'fixed', bottom: 20, right: 35}} onClick={ deleteLiveDataInstruments} >
            <DeleteSharpIcon /> 
          </Fab>
        </>
        : null
      }

      <SideBar liveTabPref={liveDataTables} setLiveTabPref={setLiveDataTables} visiblityShow={liveDataColumnVisiblityShow}  setVisiblityModel={setColumnVisibilityModel} visiblityModel={columnVisibilityModel}/>

    </Box>
  )
}

export default LiveData;