// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './features/slices/themes/themeSlice';
import userSlice from './features/slices/users/userSlice';
import alertSlice from './features/slices/alert/TransitionAlertsSlice';
import searchSlice from './features/slices/search/searchSlice';
import loaderSlice from './features/slices/loader/loaderSlice';
import tableRowSlice from './features/slices/table/tableRowSlice';
import tablePrefrenceSlice from './features/slices/table/tablePrefrenceSlice';
import tabSlice from "./features/slices/tabs/tabSlice";
import socketSlice from "./features/slices/socket/socketSlice";
import eventSlice from "./features/slices/events/eventSlice";
import oiSlice from './features/slices/oi/oiSlice';
import engagementSlice from './features/slices/engagement/engagementSlice';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    tableRow: tableRowSlice,
    user: userSlice,
    notifications: alertSlice,
    tablePrefrence: tablePrefrenceSlice,
    loader: loaderSlice,
    socket: socketSlice,
    search: searchSlice,
    tab: tabSlice,
    events: eventSlice,
    oi: oiSlice,
    engagement: engagementSlice,
  },
});

export default store;
