// src/oiSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUser } from "../../../utils/axiosInstance";

const initialState = {
    engagementTime: 0,
    startTime: Date.now()
};

export const updateEngagementDB = createAsyncThunk('engagement/updateEngagement', async (req) => {
    const response = apiUser.get(`user/engagement/${req?.engagement ?? 0}`)
    return response.data;
});

const engagementSlice = createSlice({
    name: 'engagement',
    initialState,
    reducers: {
        updateStartTime: (state, action) =>{
            if(action.payload === null)
                state.startTime = null;
            else
                state.startTime = Date.now();
        },
        updateEngagement: (state, action) =>{
            state.engagementTime = action.payload ?? 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateEngagementDB.fulfilled, (state, action) => { 
                state.engagementTime = 0;
                state.startTime = null;
            })
        }
    }
);

export const { updateStartTime, updateEngagement } = engagementSlice.actions;

export default engagementSlice.reducer;
