import React from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { histAPI } from "../../utils/axiosInstance";

import LineChart from "../Charts/LineChart";

const options = [
    {
        label:"1d",
        req: {
            days: 1,
            aggregateWindow: "1m"
        }
    },
    {
        label:"3d",
        req: {
            days: 3,
            aggregateWindow: "3m"
        }
    },
    {
        label:"5d",
        req: {
            days: 5,
            aggregateWindow: "5m"
        }
    },
    {
        label:"7d",
        req: {
            days: 7,
            aggregateWindow: "7m"
        }
    },
    {
        label:"15d",
        req: {
            days: 15,
            aggregateWindow: "10m"
        }
    },
    {
        label:"30d",
        req: {
            days: 30,
            aggregateWindow: "25m"
        }
    },
]

const Chart = ({instrument = "", expiry= "", change=0, price=0, iv=null}) => {
    const [rowData, setRowData] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState(1);

    const getPrice = React.useCallback(async()=>{
        if (instrument){
            const data = await histAPI.post('charts/get-data', {
                bucket: instrument,
                measurement: expiry,
                field: ["price"], ...options[tabIndex]["req"]
            })
            if (data.status === 200 && data.data.success){
                setRowData(data.data.data)
            }
        }
    }, [instrument, expiry, tabIndex])

    React.useEffect(()=>{
        getPrice();
    }, [instrument, expiry, getPrice])

    return (
        <Box sx={{width: '75vw'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', borderBottom: 1, borderColor: 'divider'}}>
                    <Box sx={{ width: '50%' }}>
                        {change ? <Typography variant='body1'>{instrument}:{price} <span style={{color:(change >= 0)? '#22c55e' : '#f72b2b'}}>{`(${(change >= 0)?'+':''}${change}%)`}</span></Typography>: null}
                    </Box>
                    <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                        {iv ? <Typography variant='body1'>IV: {iv}</Typography>: null}
                    </Box>
                </Box>
                <Tabs centered value={tabIndex} onChange={(e, v)=> setTabIndex(v)} aria-label="MWPL tabs">
                    {
                        options.map((d, i)=>(
                            <Tab sx={{textTransform :"none"}} label={d.label} value={i}/>
                        ))
                    }
                </Tabs>
                <Box>
                    {
                        rowData.length ? <LineChart data={rowData} change={change}/> : <Typography sx={{textAlign:'center'}}>Loading...</Typography>
                    }
                </Box>
            </Box>
            
        </Box>
    )
}

export default Chart;