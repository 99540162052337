// src/oiSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { kiteAPI } from "../../../utils/axiosInstance";

const initialState = {
    oiList: {
        list: [],
        status: 'loading'
    }
};

export const getOiList = createAsyncThunk('oi/get-all', async () => {
    const response = await kiteAPI.get("oi/get-all");
    return response.data;
});

const oiSlice = createSlice({
  name: 'oi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getOiList.fulfilled, (state, action) => {
        if (action.payload && action.payload.success){
            state.oiList.status = 'success';
            state.oiList.list = action.payload.data;
        }else{
            state.oiList.status = 'failed';
            state.oiList.list = [];
        }
    })
    .addCase(getOiList.rejected, (state, action) => {
        state.oiList.status = 'failed';
        state.oiList.list = [];
    })
  }
});

export const { } = oiSlice.actions;

export default oiSlice.reducer;
