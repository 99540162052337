import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import KeyTwoToneIcon from '@mui/icons-material/KeyTwoTone';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {apiUser} from '../../../utils/axiosInstance';
import { useSelector, useDispatch } from 'react-redux';
import { createAlert } from "../../../features/slices/alert/TransitionAlertsSlice";
import { getLoginUser } from "../../../features/slices/users/userSlice";
import { setTab } from "../../../features/slices/tabs/tabSlice";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        EliteDelta LLP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function ChangePassword() {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = React.useState('');
  const [oldPasswordErr, setOldPasswordErr] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState('');
  const loginData = useSelector((state) => state.user.loginData);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }
    setIsSubmitting(true);
    const body = {
      oldPassword,
      newPassword
    }

    try {
      const response = await apiUser.post('user/change-password', body);
      if (response.data){
        setOldPasswordErr(response.data.lastError)
        dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
        if (response.data.success){
          dispatch(setTab('live'))
          dispatch(getLoginUser());
        }
      }
      
    } catch (error) {
      dispatch(createAlert({message: "Thing doesn't seems good...", type: 'error', timeout: 3000}))
    }
      
    
    setError('');
    setIsSubmitting(false);
  };

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <KeyTwoToneIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          {loginData && loginData.setupPassword ? "Let's Setup Your Password..." : "Let's Change Your Password..."}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {loginData && !loginData.setupPassword && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="old_password"
                label="Last Password"
                type="password"
                id="old_password"
                autoComplete="old_password"
                value={oldPassword}
                error={oldPasswordErr}
                onChange={(e) => {setOldPassword(e.target.value); setOldPasswordErr(false)}}
              />
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              name="new_password"
              label="New Password"
              type="password"
              id="new_password"
              autoComplete="new_password"
              value={newPassword}
              error={(newPassword !== oldPassword ? false: newPassword && oldPassword ?  true : false) || ( newPassword && newPassword.length < 8 ? true : false)}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              autoComplete="confirm_password"
              value={confirmPassword}
              error={newPassword === confirmPassword ? false: newPassword && confirmPassword ?  true : false}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
            <Button
              disabled={isSubmitting || !newPassword || !confirmPassword || (newPassword !== confirmPassword) || (loginData && !loginData.setupPassword && !oldPassword) ||newPassword.length < 8 }
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    );
}