import moment from 'moment';

const dateComparator = (v1, v2, cellParams1, cellParams2) => {
    const dateFormat = 'DD MMM YYYY';
    
    const date1 = moment(v1, dateFormat);
    const date2 = moment(v2, dateFormat);

    let comparison = 0;

    if (date1.isBefore(date2)) {
        comparison = -1;
    } else if (date1.isAfter(date2)) {
        comparison = 1;
    }

    return comparison;
};

const dateComparatorDesc = (v1, v2, cellParams1, cellParams2) => {
    const dateFormat = 'DD MMM YYYY';
    
    const date1 = moment(v1, dateFormat);
    const date2 = moment(v2, dateFormat);

    let comparison = 0;

    if (date1.isBefore(date2)) {
        comparison = -1;
    } else if (date1.isAfter(date2)) {
        comparison = 1;
    }

    return -comparison;
};

export const getSortComparator = (order = 'asc') => {
    return order === 'asc' ? dateComparator : dateComparatorDesc;
}
