import React from 'react'
import './MarketIndices.css';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {SocketContext}  from '../../utils/SocketContext';
import { emitDataSocket } from "../../features/slices/socket/socketSlice";
import { getExpList, clearExpList } from "../../features/slices/search/searchSlice";
import { updateChain, updateChart } from "../../features/slices/table/tablePrefrenceSlice";
import moment from 'moment-timezone';
import StackedLineChartSharpIcon from '@mui/icons-material/StackedLineChartSharp';

const MarketIndices = () => {
  const dispatch = useDispatch();
  const { socket } = React.useContext(SocketContext);
  const expList  = useSelector((state) => state.search.indicesExp.list);
  const updateInterval  = useSelector((state) => state.socket.updateInterval);
  const [indices, setIndices] = React.useState([])
  const [indicesData, setIndicesData] = React.useState({})
  const [instExp, setInstExp] = React.useState({})

  const handleOpenExp = (instrument) => {
    setInstExp(instrument)
    dispatch(getExpList(instrument));
  };
  const handleCloseExp = () => {
    dispatch(clearExpList()); 
    setInstExp("");
  };
  
  const handleExpiryChange = (exp, instrument) =>{
    const req = {
        "event": "update_preferences",
        "pref_type": "indices",
        "type": "",
        "instrument": instrument,
        "expiry": exp,
      }
    dispatch(emitDataSocket({ req, socket}))
  }
  const handleHeaderDoubleClick = (instrument, exp) =>{
        dispatch(updateChain({
        "instrument": instrument,
        "expiry": exp
    }))
  }

  React.useEffect(()=>{
    if(socket){
      socket.on("marketIndices", (receivedData) => {
          const obj_keys = Object.keys(receivedData)
          
          if (obj_keys.length !== 0){
              if (indices.length !== obj_keys.length)
                  setIndices(obj_keys)
                  
          setIndicesData(receivedData)
          }
      })

      socket.emit('g_data', 'indices')
    }
    
    const interval = setInterval(() => {

      if (document.visibilityState === 'visible') {
        const now = moment().tz("Asia/Kolkata");
        const dayOfWeek = now.isoWeekday(); // 1: Monday, ..., 7: Sunday
        const start = moment().tz("Asia/Kolkata").set({ hour: 9, minute: 0, second: 0 });
        const end = moment().tz("Asia/Kolkata").set({ hour: 15, minute: 30, second: 0 });

        if (dayOfWeek >= 1 && dayOfWeek <= 5 && now.isBetween(start, end) && socket) {
          socket.emit('g_data', 'indices')
        }
      }
    
    }, 1000*updateInterval);

    return () =>{
      if(socket){
        socket.off("marketIndices")
      }
      clearInterval(interval);
    }
  }, [socket, indices, updateInterval])
  
  const openChart = (instrument, expiry, change, price, iv) => {
    dispatch(updateChart({ instrument, expiry, change, price, iv }));
  }

  return (
    <Box sx={{width: '95%', padding: '1rem', margin: '0px auto', display: 'flex', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-around' }}>
        {
        indices.map((v)=>
          <Card key={indicesData[v]?.name} sx={{ minWidth: 250, maxWidth: 250, margin: 2, padding:1 }}>
            <CardContent sx={{padding: 1, display:'flex', justifyContent:'center'}} >
              <Button sx={{ color:/[-]/i.test(indicesData[v]?.change) ? '#f72b2b': '#22c55e' }} onClick={()=>handleHeaderDoubleClick(indicesData[v]?.name, indicesData[v]?.exp)} variant="outlined">{`${indicesData[v]?.name.toUpperCase()} (${indicesData[v]?.change ?? ''})`}</Button>
              {/* <Typography sx={{cursor:'pointer'}}  align='center' variant="h5" component="div" 
              </Typography> */}
            </CardContent>
            <CardActions>
              <FormControl fullWidth sx={{width: '65%', margin: '0 auto'}}>
                <Select variant='standard' sx={{textAlign: 'center', padding: 0, margin: 0}} onChange={(e)=>handleExpiryChange(e.target.value, indicesData[v]?.name)} open={instExp === indicesData[v]?.name } onOpen={() => handleOpenExp(indicesData[v]?.name)} onClose={handleCloseExp} value={indicesData[v].exp}>
                {
                    (instExp === indicesData[v]?.name && expList.length ? expList : [indicesData[v].exp]).map((de, index)=>
                        <MenuItem onDoubleClick={ () => handleHeaderDoubleClick(indicesData[v]?.name, de) } key={`${de}_${index}`} value={de}>{de}</MenuItem>
                    )
                }
                </Select>
              </FormControl>
            </CardActions>
            <CardContent sx={{padding: 1, color: '#c0fb13'}}>
              <Tooltip title={"Implied Future, Click to Open chart !"}  placement="left">
                <Typography align='center' sx={{cursor: 'pointer'}} variant="h6" component="div" onClick={()=> openChart(indicesData[v]?.name, indicesData[v].exp, indicesData[v]?.change, indicesData[v]?.fair_price, indicesData[v]?.iv )}>
                {`${indicesData[v]?.fair_price - (indicesData[v]?.discount ?? 0) }`} <StackedLineChartSharpIcon sx={{color: 'white'}} />
                </Typography>
              </Tooltip>
              <Tooltip title={"IV"} placement="left">
                <Typography align='center' sx={{cursor: 'default'}} variant="h6" component="div">
                {`${indicesData[v]?.iv}`}
                </Typography>
              </Tooltip>
            </CardContent>
          </Card>
        )
        }
    </Box>

  )
}

export default MarketIndices