import * as React from 'react';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import moment from 'moment';

Chart.register(annotationPlugin);

const LineChart = ({ data, change = 1 }) => {
    const canvasRef = React.useRef(null);
    const chartRef = React.useRef(null);
    const initialPoint = React.useRef({});
    const dragging = React.useRef(false);
    const label = React.useRef({});
  
    React.useEffect(() => {
      const ctx = canvasRef.current.getContext('2d');
      // Destroy the existing chart if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      if(data.length){
        chartRef.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: data.map(d => d[0]),
            datasets: [{
              label: 'Price',
              fill: false,
              data: data.map(d => d[1]),
              backgroundColor: 'rgba(70,130,180,0.4)',
              pointRadius: 1,
              pointHoverRadius: 1.1,
              segment: {
                borderColor: ctx => {
                  const { start, end } = dragging.current ? dragging : { start: 0, end: 0 };
                  const index = ctx.p0DataIndex;
                  const [minIndex, maxIndex] = start < end ? [start, end] : [end, start];
                  if (index >= minIndex && index < maxIndex) {
                    // return dragging.percentageChange < 0 ? 'red' : 'green'; 
                    return 'rgb(54, 162, 235)';
                  }
                  return (change > 0 ? '#22c55e' : '#f72b2b');
                  // return dragging.current ? 'red' : 'green' ;
                },
              }
            }]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: true,
                position: 'nearest',
                intersect: false,
                axis: 'x', 
                // Scale tooltip based on device pixel ratio
                bodyFont: {
                    size: 21
                },
                titleFont: {
                    size: 21
                },
                titleColor: () => (dragging.percentageChange == null) ? 'white' : (dragging.percentageChange > 0) ? '#22c55e' : '#f72b2b',
                callbacks: {
                  title: (th) => {
                    if (dragging?.percentageChange != null) {
                      let start = "";
                      let end = "";
                      
                      const diff = label.Start.diff(label.End, 'h') ?? 0;
                      
                      if(label.diff > 0){
                        start = label.Start.format(Math.abs(diff) <= 6 ? 'DD MMM hh:mm A' : 'DD MMM hh:mm')
                        end = label.End.format(Math.abs(diff) <= 6 ? 'hh:mm A' : 'DD MMM hh:mm')
                      }else{
                        end = label.Start.format(Math.abs(diff) <= 6 ? 'DD MMM hh:mm A' : 'DD MMM hh:mm')
                        start = label.End.format(Math.abs(diff) <= 6 ? 'hh:mm A' : 'DD MMM hh:mm')
                      }
                      return `${dragging.percentageChange}% ${start} - ${end}`;
                    }
                    else if (th) {
                      const date = moment(chartRef.current.data.labels[th[0].dataIndex])
                      return (`${th[0].formattedValue} ${date.format('ddd, DD MMM hh:mm A')}`);
                    }
                  },
                  label: () => null
                }
              },
            },
            scales: {
              x: {
                type: 'timeseries',
                offset: true,
                ticks: {
                  major: {
                    enabled: true,
                  },
                  color: 'white',
                  maxRotation: 0,
                  autoSkip: true,
                  autoSkipPadding: 75,
                },
              },
              y: {
                type: 'linear',
                ticks: {
                  color: 'white'
                }
              }
            },
            animation: {
              duration: 5,
              easing: 'easeOutQuad'
            },
            events: ['mousemove', 'mousedown', 'mouseup', 'touchstart', 'touchmove'],
            onHover: function (e) {
              const chart = this;
              const { native } = e;
    
              if (native) {
                const points = chart.getElementsAtEventForMode(native, 'nearest', { intersect: false, axis: 'x' }, true);
    
                if (native.type === 'mousedown' || native.type === 'touchstart') {
                  if (points.length) {
                    const nearestPoint = points[0];
                    initialPoint.current = {
                      datasetIndex: nearestPoint.datasetIndex,
                      index: nearestPoint.index
                    };
                    dragging.current = true;
                    dragging.percentageChange = null;
                    dragging.start = nearestPoint.index;
                    label.Start = moment(chart.data.labels[nearestPoint.index]);
                    dragging.end = nearestPoint.index;
                    chart.update();
                  }
                } else if ((native.type === 'mousemove' || native.type === 'touchmove') && dragging.current) {
                  if (points.length) {
                    const nearestPoint = points[0];
                    const newX = nearestPoint.index;
                    dragging.end = newX;
                    const dataset = chart.data.datasets[initialPoint.current.datasetIndex];
                    const initialY = dataset.data[dragging.start];
                    const newY = dataset.data[dragging.end];
                    label.End = moment(chart.data.labels[nearestPoint.index]);
                    label.diff = label.Start.diff(label.End) ?? 0;
                    label.oneDay = Math.abs(label.diff) < 6;
                    
                    if(label.diff > 0){
                      dragging.percentageChange = (((initialY - newY) / newY) * 100).toFixed(2);
                    }else{
                      dragging.percentageChange = (((newY - initialY) / initialY) * 100).toFixed(2);
                    }
    
                    chart.update();
                  }
                } else if (native.type === 'mouseup') {
                  dragging.current = false;
                  dragging.percentageChange = null;
                  chart.update();
                }
              }
            }
          }
        });
      }
  
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, [data]); // This ensures the chart updates when `data` changes
  
    return (
      <canvas ref={canvasRef} />
    );
};

export default LineChart;