import { createSlice } from "@reduxjs/toolkit";

export const tabSlice = createSlice({
  name: "tab",
  initialState: {
    tab: localStorage.getItem('tab') ?? 'live'
  },
  reducers: {
    setTab: (state, action) => {
      localStorage.setItem('tab', action.payload);
      state.tab = action.payload;
    }
  }
});

export const { setTab } = tabSlice.actions;

export default tabSlice.reducer;