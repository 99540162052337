import React from 'react';
import { Box, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'left',
//     color: theme.palette.text.secondary,
//   },
// }));

function PrivacyPolicy() {
//   const classes = useStyles();

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom>
        **Effective Date:** 24 July 2024
      </Typography>
      <Typography variant="body1" paragraph>
        **1. Introduction**
        <br />
        ELITEDELTA LLP we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our application that utilizes Google Sign-In.
      </Typography>
      <Typography variant="body1" paragraph>
        **2. Information We Collect**
        <br />
        When you use Google Sign-In to access our application, we may collect the following information:
        <br />
        - **Google Account Information:** Your name, email address, profile picture, and any other information associated with your Google account.
        <br />
        - **Usage Data:** Information about how you interact with our application, including access times, pages viewed, and other user actions.
      </Typography>
      <Typography variant="body1" paragraph>
        **3. How We Use Your Information**
        <br />
        We use the information we collect to:
        <br />
        - Authenticate your identity and provide access to our application.
        <br />
        - Personalize your user experience.
        <br />
        - Improve our application and services.
        <br />
        - Communicate with you about updates, security alerts, and other relevant information.
        <br />
        - Comply with legal obligations.
      </Typography>
      <Typography variant="body1" paragraph>
        **4. Information Sharing and Disclosure**
        <br />
        We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
        <br />
        - **Service Providers:** We may share your information with third-party service providers who perform services on our behalf, such as hosting and data analysis.
        <br />
        - **Legal Requirements:** We may disclose your information if required to do so by law or in response to a legal request.
      </Typography>
      <Typography variant="body1" paragraph>
        **5. Data Security**
        <br />
        We take reasonable measures to protect your information from unauthorized access, loss, misuse, or alteration. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee its absolute security.
      </Typography>
      <Typography variant="body1" paragraph>
        **6. Your Choices**
        <br />
        You can manage your Google account information through your Google account settings. If you wish to delete your account or any personal information we hold about you, please contact us at chandoliagulshan@gmail.com.
      </Typography>
      <Typography variant="body1" paragraph>
        **7. Changes to This Privacy Policy**
        <br />
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our application. You are advised to review this Privacy Policy periodically for any changes.
      </Typography>
      <Typography variant="body1" paragraph>
        **8. Contact Us**
        <br />
        If you have any questions about this Privacy Policy, please contact us at:
        <br />
        ELITEDELTA LLP
        <br />
        Jaipur, Rajasthan, India
        <br />
        chandoliagulshan@gmail.com
      </Typography>
    </Box>
  );
}

export default PrivacyPolicy;
