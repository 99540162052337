import './TransitionAlerts.css';
import * as React from 'react';
import {Snackbar, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Alert, Slide, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { updateEngagementDB } from "../../features/slices/engagement/engagementSlice";
import { removeAlert, removeDialogAlert } from "../../features/slices/alert/TransitionAlertsSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TransitionAlerts = () => {

    const { alerts } = useSelector(state => state.notifications);
    const dispatch = useDispatch();
    
    const handleClose = (idex, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(removeAlert({index: idex}))
    }

    return(
        alerts?.map((a, index)=>(
            <Snackbar key={`${a}_${index}`} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ mb: 2 }} open={a ? true : false} autoHideDuration={a.timeout ?? (a.type === 'error' ? 5000 : 2500)} onClose={(e,r) => handleClose(index,r) }>
                <Alert severity={a.type} action={<IconButton aria-label="close" color="inherit" size="small" onClick={(e,r) => { handleClose(index, r)}}> <CloseIcon fontSize="inherit" /> </IconButton>}  >{a.message}</Alert>
            </Snackbar>
        ))
    )
}

export const DialogAlert = () =>{
    
    const { connectSocket } = React.useContext(require('../../utils/SocketContext').SocketContext); 
    const dialogs = useSelector(state => state.notifications.dialogs);
    const dispatch = useDispatch();
    const engagementTime  = useSelector((state) => state.engagement.engagementTime);
    const startTime  = useSelector((state) => state.engagement.startTime);

    const handleButtonClick = (actionType, index) => {
        switch (actionType) {
            case 'SESSION_RESTORE':
                connectSocket();
                dispatch(removeDialogAlert({index}))
                break;
            default:
                break;
        }
    };

    const handleClose = (idex, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        const timeSpent = ~~(Date.now() - startTime);
        dispatch(updateEngagementDB({engagement: ~~((engagementTime + timeSpent)/1000)}));
        dispatch(removeDialogAlert({index: idex}));
    }

    return (
        dialogs?.map((a, index)=>(
            <Dialog
                fullWidth
                key={`dialog_${index}`}
                maxWidth={'xs'}
                open={true}
                TransitionComponent={Transition}
                keepMounted
                onClose={(e,r) => handleClose(index,r)}
                aria-describedby="alert-dialog"
            >
                {
                    a?.title ? <DialogTitle>{a.title}</DialogTitle> : null
                }
                {
                    a?.message ? 
                        <DialogContent>
                            <DialogContentText id="alert-dialog">{a.message}</DialogContentText>
                        </DialogContent>
                    : null
                }
                <DialogActions>
                    {
                        a?.buttons?.map((b, kInd)=>{
                            return(
                                <Button key={`dialog_btm_${kInd}`} variant='outlined' onClick={() => handleButtonClick(b.actionType, index)}>{b.name}</Button>
                            )
                        })
                    }
                </DialogActions>
            </Dialog>)
        )
    )
}