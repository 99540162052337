import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import EcoCalendar from "../../Tables/EcoCalendar";
import EventsTable from '../../Tables/EventsTable';
import QtrResults from '../../Tables/QtrResults';

export default function Events() {
    const [value, setValue] = React.useState('ec');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <Box >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:2 }}>
            <Tabs centered value={value} onChange={handleChange} aria-label="MWPL tabs">
                <Tab label="Economic Calender" value={'ec'}/>
                <Tab label="Corp. Action" value={'corp_action'}/>
                <Tab label="Board Meeting" value={'comp_ev'}/>
                <Tab label="Qtr Results" value={'qr'}/>
            </Tabs>
        </Box>
        {
            value === 'ec'? <EcoCalendar /> : value === 'corp_action'? <EventsTable eventType={value} /> : value === 'qr'? <QtrResults/> : value === 'comp_ev'? <EventsTable eventType={value} /> :  null
        }
    </Box>
  )
}