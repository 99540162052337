// src/App.js
import React from 'react';
import { ThemeProvider } from './ThemeContext';
import SignIn from "./components/Auth/SignIn/SignIn";
import TopAppBar from './components/AppBar/AppBar';
import { getLoginUser, getCheckLogin } from './features/slices/users/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import {TransitionAlerts, DialogAlert} from "./components/TransitionAlerts/TransitionAlerts";
import { SnackbarProvider } from 'notistack';
import Home from "./components/Home/Home";
import { GoogleOAuthProvider } from "@react-oauth/google"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SocketProvider } from './utils/SocketContext';
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Loading from './components/Loader/Loader/Loading';
import DataModal from './components/Modal/DataModal';

const App = () => {
  
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.loggedIn);
  const loginData = useSelector((state) => state.user.loginData);

  React.useEffect(()=>{
    if(loginData === null && auth)
      dispatch(getLoginUser());

    if(auth === null)
      dispatch(getCheckLogin());

    const interval = setInterval(() => {
      if(auth && document.visibilityState === 'visible')
        dispatch(getCheckLogin());
    }, 30000); // check login each 30 sec
    return () =>{
      clearInterval(interval);
    }
  },[auth, loginData, dispatch]);

  return (   
    <ThemeProvider>
      <SnackbarProvider maxSnack={3}>
        <TransitionAlerts />
        <Router>
          <Routes>
            <Route path="/" element={
              auth === false ?
                <GoogleOAuthProvider clientId={process.env.REACT_APP_google_clientId}>
                  <SignIn/>
                </GoogleOAuthProvider>
              : auth === true ?
                <SocketProvider>
                  <DialogAlert />
                  <TopAppBar />
                  <Home />
                  <DataModal/>
                </SocketProvider>
              : <Loading/>
              } />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
