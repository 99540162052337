import * as React from 'react';
import { Fab } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function SideBar({visiblityModel, setVisiblityModel, visiblityShow, setLiveTabPref, liveTabPref}) {
  const [open, setOpen] = React.useState(true);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
        return;
    }

    setOpenDrawer(open);
  };

  const toggleLiveTable = (index) => {
    setLiveTabPref((__d) => {
        __d[index] = {...__d[index], show: !(__d[index].show)}
        return [...__d]
    })
  }

  const handleTableVisiblityClick = (k,v) => {
    
    setVisiblityModel((obj)=>{
        obj[k] = v
        return {...obj}
    })
    localStorage.setItem('tableVisiblityPref', JSON.stringify(visiblityModel))
  }

  return (
    <>
        {
            !openDrawer ? 
                <Fab size='large' color='primary' variant="extended" sx={{position: 'fixed', top: '35vh', right: -18}} onClick={toggleDrawer(true)}>
                    <ArrowBackIosIcon onClick={toggleDrawer(true)} /> 
                </Fab> 
            : null
        }
        
        <Drawer
            anchor={'right'}
            open={openDrawer}
            onClose={toggleDrawer(false)}
        >
            <List
                sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">User Prefrences</ListSubheader>
                }
            >
                {
                    liveTabPref.map((v, i)=>
                        <ListItem key={v?.name+'_'+i} onClick={()=>{toggleLiveTable(i)}}>
                            <ListItemText primary={v?.name} sx={{color: v?.color}}/>
                            <Switch size={'small'} checked={v.show}/>
                        </ListItem>
                    )
                }
            <ListItemButton onClick={handleClick}>
                <ListItemText primary="Columns Visiblity" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        Object.keys(visiblityShow).map((k)=>
                            <ListItemButton key={`opt_${k}`} sx={{ pl: 4, }} onClick={()=>handleTableVisiblityClick(visiblityShow[k], !visiblityModel[visiblityShow[k]])}>
                                <ListItemText sx={{opacity: visiblityModel[visiblityShow[k]]? 1 : .2}} primary={k} />
                            </ListItemButton>
                        )
                    }
                    
                </List>
            </Collapse>
            </List>
        </Drawer>
    </>
    
  );
}