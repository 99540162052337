export const sortRows = (rows, sortRules) => {
    return rows.sort((a, b) => {
      for (const { columnKey, direction, sortComparator } of sortRules) {
        const comparator = direction === 'ASC' ? 1 : -1;
        if (sortComparator){
          const diff = sortComparator(a[columnKey], b[columnKey]);
          if(diff > 0) return -comparator 
          if(diff < 0) return comparator

        }else{
          if (a[columnKey] > b[columnKey]) return comparator;
          if (a[columnKey] < b[columnKey]) return -comparator;
        }
      }
      return 0;
    });
};

// Define the aggregator function
export const sum = (rows, key) => {
  return rows.reduce((acc, row) => acc + Number(row[key]), 0);
};

export const rowGrouper = (rows, columnKey) => {
  const groups = {};
  rows.forEach(row => {
    const groupKey = row[columnKey];
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(row);
  });

  return groups;
};