import React from 'react';
import { TextField, Button, Grid, Box, Modal } from '@mui/material';
import { apiUser } from "../../utils/axiosInstance";
import { createAlert } from "../../features/slices/alert/TransitionAlertsSlice";
import { useDispatch } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50vw',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'black',
    height: 'auto',
    outline:'none',
    // boxShadow: 24,
    p: '0.2rem', 
};

const FeedBackModal = ({open=false, handleClose=()=>{}}) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({
        msg: ''
    });
    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await apiUser.post('user/feedback', formData);
        if (res.status === 200){
            if(res.data.message){
                dispatch(createAlert({message: res.data.message, type: res.data?.success ? 'success' : 'error', timeout: 2000}))
            }
            if(res.data?.success){
                handleClose()
            }       
        }else{
            dispatch(createAlert({message: "Some issue occured...", type: 'error', timeout: 2000}))
        }
    };
  return (
    <Modal
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="Chain"
        aria-describedby="Option Chain">
        <Box sx={style}>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ maxWidth: 600, margin: '0 auto', my: 4 }}
                >
                    <Grid item xs={12} mb={3}>
                        <TextField
                            fullWidth
                            placeholder="Suggest features, flag errors, or share any trader-focused improvements..."
                            label="Suggestion"
                            name="msg"
                            variant="outlined"
                            multiline
                            rows={5}
                            value={formData.feedback}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" disabled={!formData.msg} variant="contained" color="primary" fullWidth>
                            Submit
                        </Button>
                    </Grid>
            </Box>  
        </Box>
    </Modal>
  )
}

export default FeedBackModal