import React from 'react';
import { Box } from '@mui/material';
import { TreeDataGrid } from 'react-data-grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { sortRows, rowGrouper } from "./tableFunctions";
import { kiteAPI } from "../../utils/axiosInstance";
import moment from 'moment';

const columns = [
    {
        key: "portfolio",
        resizable: true,
        sortable: true,
        minWidth: 150,
        type: "string",
        name: "Portfolio",
    },
    {
        key: "eventDate",
        resizable: true,
        sortable: true,
        minWidth: 110,
        sortComparator: (a, b) => moment(a, 'DD MMM YYYY').diff(moment(b, 'DD MMM YYYY')),
        type: "string",
        renderGroupCell: (row) => {
            const r = row.childRows[0]
            return <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{row.groupKey} ({r.eventDay}-Q{r.quarter}) {row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
        },
        name: "Date",
    },
];

export default function QtrResults({eventType}) {
    const [rows, setRows] = React.useState([]);
    const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set([moment().format('YYYY-MM-DD').toString()]));
    const [list, setList] = React.useState([]);
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [sortColumn, setSortColumn] = React.useState([{
        "columnKey": "eventDate",
        "direction": "ASC"
    }]);

    const handleSort = (s) => {
        
        setSortColumn(s.map((s1)=>{
            const ind = columns.findIndex((i)=> s1.columnKey === i.key)
            if (ind !== -1){
                return(columns[ind].sortComparator ?{...s1, sortComparator: columns[ind].sortComparator} : s1)
            }else{
                return(s1);
            }
        }));
    };

    const getData = React.useCallback(async () => {
        const response = await kiteAPI.get(`events/get/results?fromDate=${fromDate}&toDate=${toDate}&list=true`);
        if (response.status === 200 && response.data.data){
            setList(response.data.data)
        }else{
            setList([])
        }
        setLoading(false)
    }, [fromDate, toDate, eventType])

    React.useEffect(()=>{
        setLoading(true)
        getData()
    }, [eventType])

    React.useEffect(()=>{
        setRows(sortColumn.length ? sortRows(list, sortColumn) : list);
    },[sortColumn, list]);

  return (
    <Box sx={{width: '95%', margin: '0px auto'}}>
        {
            loading ? <Box sx={{textAlign: 'center', fontSize: '2rem'}}>Loading...</Box> : 
            rows.length ? 
            <TreeDataGrid style={{height: "auto", textAlign: 'center'}} columns={columns} groupBy={['eventDate']} rowGrouper={rowGrouper} sortColumns={sortColumn} onSortColumnsChange={handleSort} expandedGroupIds={expandedGroupIds} onExpandedGroupIdsChange={setExpandedGroupIds} rows={rows}/>
            : <Box sx={{textAlign: 'center', fontSize: '2rem'}}>No F&O Stocks...</Box>
        }
    </Box>
  )
}