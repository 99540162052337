import React from 'react';
import { Box } from '@mui/material';
import { TreeDataGrid } from 'react-data-grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { sortRows, rowGrouper } from "./tableFunctions";
import { kiteAPI } from "../../utils/axiosInstance";
const moment = require('moment');

const columns = [
    {
        key: "country",
        resizable: true,
        sortable: true,
        minWidth: 75,
        type: "string",
        renderCell: (c)=> (<span style={{textTransform: 'capitalize'}}>{(c.row.country ?? "")}</span>),
        name: "Country",
    },
    {
        key: "eventDate",
        resizable: true,
        sortable: true,
        minWidth: 110,
        renderGroupCell: (row) => {
            const r = row.childRows[0]
            return <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{row.groupKey} ({r.eventDay}) {row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
        },
        sortComparator: (a, b) => moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD')),
        name: "Date",
    },
    {
        key: "eventTime",
        resizable: true,
        sortable: true,
        minWidth: 75,
        renderCell: (c)=> (<span >{c.row.eventTime && c.row.eventTime !== "None"? moment(c.row.eventTime, "HH:mm:ss").format("hh:mm A") : "-"}</span>),
        sortComparator: (a, b) => {
            if (!a) return 1;
            if (!b) return -1;
      
            const timeA = moment(a, "HH:mm:ss", true);
            const timeB = moment(b, "HH:mm:ss", true);
      
            if (!timeA.isValid()) return 1;
            if (!timeB.isValid()) return -1;
      
            return timeA - timeB;
        },
        name: "Time",
    },
    {
        key: "description",
        resizable: true,
        sortable: true,
        minWidth: 200,
        type: "string",
        renderCell: (c)=> (<span>{`${c.row.description}${c.row.reference ? ` - ${c.row.reference}` : ""}`} </span>),
        name: "Event",

    }
]

export default function EcoCalendar({}) {
    const [rows, setRows] = React.useState([]);
    const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set([moment().format('YYYY-MM-DD').toString()]));
    const [list, setList] = React.useState([]);
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    const [sortColumn, setSortColumn] = React.useState([{
        "columnKey": "eventDate",
        "direction": "ASC"
    }]);

    const handleSort = (s) => {
        setSortColumn(s.map((s1)=>{
            const ind = columns.findIndex((i)=> s1.columnKey === i.key)
            if (ind !== -1){
                return(columns[ind].sortComparator ?{...s1, sortComparator: columns[ind].sortComparator} : s1)
            }else{
                return(s1);
            }
        }));
    };

    const getData = React.useCallback(async () => {
        const response = await kiteAPI.get(`events/get/global_events?fromDate=${fromDate}&toDate=${toDate}`);
        if (response.status === 200 && response.data.data){
            setList(response.data.data)
        }else{
            setList([])
        }
    }, [fromDate, toDate])

    React.useEffect(()=>{
        getData()
        
    }, [])

    React.useEffect(()=>{
        setRows(sortColumn.length ? sortRows(list, sortColumn) : list);
    },[sortColumn, list]);

  return (
    <Box sx={{width: '95%', margin: '0px auto'}}>
        {
            rows.length ?
                <TreeDataGrid style={{height: "auto", textAlign: 'center'}} columns={columns} groupBy={['eventDate']} rowGrouper={rowGrouper} sortColumns={sortColumn} onSortColumnsChange={handleSort} expandedGroupIds={expandedGroupIds} onExpandedGroupIdsChange={setExpandedGroupIds} rows={rows}/>
            : <Box sx={{textAlign: 'center', fontSize: '2rem'}}>No F&O Stocks...</Box>
        }
    </Box>
  )
}