import { Autocomplete, TextField } from '@mui/material'
import './SelectInstrument.css'
import React from 'react'

const SelectInstrument = ({label = null, placeholder = null, list = null, loading = false, onChange = (e)=>{}, getList = null, width= null }) => {

    return (
        <Autocomplete
            clearOnEscape
            clearOnBlur
            size='small'
            onChange={(e, v) => { onChange(v)  }}
            id="combo-box-demo"
            loading={loading}
            loadingText={`Just a sec...`}
            // noOptionsText={`Type in something...`}
            options={list ?? []}
            sx={{ width: width ?? 300 }}
            renderInput={(params) => <TextField onChange={(e) => getList ? getList(e.target.value) : null} placeholder={placeholder ?? 'Select instrument...'}  {...params} />}
        />
    )
}

export default SelectInstrument;