import { createSlice } from "@reduxjs/toolkit";

export const LoaderSlice = createSlice({
  name: "loader",
  initialState: {
    loader: false
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    }
  }
});

export const { setLoader } = LoaderSlice.actions;

export default LoaderSlice.reducer;